<template>
  <trac-loading class="pt-64" v-if="loading" />
  <main v-else class="mr-4 md:mr-0">
    <div class="mt-3 flex items-center w-full justify-between">
      <div v-if="(fixedAccountVerified)">
        <!-- <trac-back-button>Settings</trac-back-button> -->
        <h1 class="text-sm mt-2 font-medium">Your fixed accounts</h1>
      </div>

      <div v-if="(fixedAccountVerified)">
        <trac-button @click.native="newAccount = !newAccount">
          <span class="px-1 uppercase">Create fixed account</span>
        </trac-button>
      </div>
    </div>
    <div class="w-full mt-6 md:mt-12 shadow-sm p-3 bg-white rounded-lg" v-if="(fixedAccountVerified)">
      <div class="grid md:flex md:justify-between items-center my-2">
        <trac-dropdown class="w-48 ml-8" data-test="filter-by-bank-or-store" title="Filter by Bank or Store" :choice="true" v-model="selectedFilter"
          :menuItems="filterOptions"></trac-dropdown>
        <div v-if="fixedAccount.length !== 0" class="relative max-w-sm py-5 rounded-md w-full flex items-center">
          <input type="text" data-test="search-field" placeholder="Search by customer,phone, email"
            class="input-shadow py-2 w-full md:ml-8 text-xs pl-5 focus:outline-none leading-7 tracking-wide text-primaryGray"
            v-model="search" />
          <svg data-test="search-icon" class="w-4 h-4 absolute right-0 mr-6" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6.56705" cy="6.61686" r="5.39909" stroke="#253B95" stroke-width="1.06786" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M13.3156 13.3659L10.3799 10.4302" stroke="#253B95" stroke-width="1.06786" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        <div data-test="learn-more" @click="toggleModal = !toggleModal"
          class="cursor-pointer w-full justify-end items-center flex text-right mr-10">
          <span class="text-xs text-primaryBlue"> Learn More </span>
          <svg width="7" class="ml-2" height="13" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 11.5L6.4 6.1L1 0.700001" stroke="#003283" stroke-width="1.2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      <div v-if="fixedAccount.length == 0" class="flex flex-col items-center justify-center pt-20 pb-32">
        <img class="w-32 h-32" src="@/assets/svg/fixedaccount.svg" alt="" />
        <div class="text-sm text-primaryGray">
          You do not have any fixed account created
        </div>
      </div>
      <div v-else class="md:mx-8 mb-8 border rounded-lg overflow-auto">
        <table class="w-full table-auto overflow-auto">
          <thead class="bg-blue-100">
            <tr class="h-16">
              <th class="text-left pl-8 text-xs font-semibold py-2 uppercase md:whitespace-no-wrap md:px-4">
                Bank Names
              </th>
              <th class="text-left pl-8 text-xs font-semibold py-2 uppercase md:whitespace-no-wrap md:px-4">
                Account Names
              </th>

              <th class="text-left px-5 text-xs font-semibold py-2 uppercase md:whitespace-no-wrap md:px-4">
                Account Number
              </th>
              <th class="text-left text-xs font-semibold py-2 uppercase md:whitespace-no-wrap md:px-4">
                Store Attached
              </th>
              <th class="text-left text-xs font-semibold py-2 uppercase md:whitespace-no-wrap md:px-4">
                Date Created
              </th>
            </tr>
          </thead>
          <tbody v-if="filteredAccounts.length">
            <tr class="cursor-pointer" :class="i % 2 === 0 ? '' : 'bg-gray-100'"
              v-for="(account, i) in filteredAccounts" :key="i">
              <td class="text-xs py-5 text-accentDark md:whitespace-no-wrap md:px-4 font-medium pl-8">
                {{ account.bank_name }}
              </td>
              <td class="text-xs py-5 text-accentDark md:whitespace-no-wrap md:px-4 font-medium pl-8">
                {{ account.custom_account_name }}
              </td>
              <td class="text-xs py-5 px-5 text-accentDark md:whitespace-no-wrap md:px-4 font-bold">
                {{ account.custom_account_number }}
              </td>
              <td class="text-xs py-5 text-accentDark capitalize md:whitespace-no-wrap md:px-4 font-medium">
                {{ account.store_details.store_name }}
              </td>
              <td class="text-xs py-5 text-accentDark md:whitespace-no-wrap md:px-4">
                <div class="flex flex-row">
                  {{ moment(account.created_at).format('Do-MMM-YYYY') }}
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-xs pl-5 py-5 text-accentDark">
                No Available Data
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="!newUserModal && !pendingUserModal && fixedAccountVerified">
      <trac-modal data-test="cancel-button" @close="closeModal" size="max-w-3xl"
        v-if="$store.state.miscellaneous.fixedAccountModal || toggleModal">
        <div class="p-4">
          <div class="flex w-full">
            <div class="w-56 md:flex items-center flex-col justify-center hidden">
              <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="29.5" cy="29.5" r="28.8371" fill="#11A529" fill-opacity="0.12" stroke="#B2E7FF"
                  stroke-width="1.32584" />
                <path d="M14.9336 30.2277L20.6805 37.4173C21.2863 38.1752 22.3999 38.2795 23.136 37.6473L44.0694 19.666"
                  stroke="#253B95" stroke-width="2.91358" />
              </svg>
              <div class="text-center text-primaryBlue leading-6 text-sm mt-3">
                You just turned on <br />
                the fixed account <br />
                feature.
              </div>
            </div>
            <div class="flex-1 md:border-l md:ml-3 md:pl-8 pt-20 py-24">
              <h1>About Fixed Accounts</h1>
              <p class="max-w-sm w-full text-sm text-accentDark mt-3 leading-7">
                Fixed accounts are an efficient means of recieving payment for
                your business. They are uniquely generated accounts that can be
                attached to a specific store. <br />
                Use fixed accounts by
                <span @click="$router.push({ name: 'AddStore' })"
                  class="text-primaryBlue cursor-pointer underline">creating a store
                </span>
                if you dont alreading have one. On creating the account you will
                be asked to assign a name and a store after which the account
                will be generated. <br />
                There is no limit to the amount of fixed accounts you can
                create.
              </p>
            </div>
          </div>
        </div>
      </trac-modal>
    </div>
    <trac-modal v-if="newAccount" @close="newAccount = false">
      <div class="py-5 px-20">
        <h1 class="mt-8">Create new account</h1>
        <div class="mt-12">
          <trac-input data-test="create-account-name-field" v-model="accountName" placeholder="Create Account Name"></trac-input>
        </div>
        <div class="mt-6 w-full">
          <trac-dropdown data-test="select-store-field-dropdown" class="w-full" title="Select Store" :choice="true" v-model="store"
            :menuItems="stores"></trac-dropdown>
        </div>
        <div class="mt-6 w-full">
          <trac-dropdown data-test="select-fixed-account" class="w-full" title="Select preferred fixed account Bank" :choice="true"
            v-model="selectedBank" :menuItems="banks"></trac-dropdown>
        </div>
        <div class="mt-12 pb-5 flex justify-center items-center">
          <trac-button data-test="generate-account-number-button" @click.native="createFixedAccount" class="">
            <span class="px-2 uppercase">Generate Account Number</span>
          </trac-button>
        </div>
      </div>
    </trac-modal>
    <trac-modal v-if="bvnCheck" :cancel="false">
      <p class="max-w-sm w-full text-sm text-accentDark mt-3 leading-7">
        You are seeing this prompt due to the fact that your bvn has not been
        validated properly. Kindly Navigate to the settlement account page to
        create or update your bvn in other to view this page
        <br />
        <trac-button class="mt-4" @click.native="$router.push({ name: 'BankAccounts' })">
          <span class="text-white cursor-pointer">Go to Settlement Account
          </span>
        </trac-button>
      </p>
    </trac-modal>
    <trac-modal v-if="bvnCheckTwo" :cancel="true" @close="bvnCheckTwo = false">
      <p class="max-w-sm w-full text-sm text-accentDark mt-3 leading-7">
        You are seeing this prompt due to the fact that your bvn has not been
        validated properly. Kindly Navigate to the settlement account page to
        create or update your bvn in other to view this page
        <br />
        <trac-button class="mt-4" @click.native="$router.push({ name: 'BankAccounts' })">
          <span class="text-white cursor-pointer">Go to Settlement Account
          </span>
        </trac-button>
      </p>
    </trac-modal>
    <!-- <trac-modal :cancel="false" v-if="newUserModal">
      <div class="flex flex-col justify-center items-center">
        <img class="w-64" src="@/assets/svg/fixed.svg" alt="" />
        <p class="max-w-sm w-full text-sm font-medium text-center text-accentDark mt-3 leading-7">
          Hi {{ name }}, you are one step to creating your fixed account. Kindly
          complete your KYC to proceed.
          <br />
          <trac-button class="mt-5 px-20 mb-5" @click.native="$router.push({ name: 'Photo' })">
            <span class="text-white cursor-pointer">Complete Kyc </span>
          </trac-button>
        </p>
      </div>
    </trac-modal> -->
    <trac-modal :cancel="false" v-if="pendingUserModal">
      <div class="flex flex-col justify-center items-center">
        <img class="w-40" src="@/assets/svg/pending.svg" alt="" />
        <p class="max-w-sm w-full text-sm font-medium text-center text-accentDark mt-3 leading-7">
          Your KYC verification is currently being reviewed.
          <br />
          <trac-button class="mt-5 px-20 mb-5" @click.native="$router.push({ name: 'Dashboard' })">
            <span class="text-white cursor-pointer">Back To Home</span>
          </trac-button>
        </p>
      </div>
    </trac-modal>
    <trac-modal v-if="dashError" :cancel="true" @close="dashError = false">
      <p class="max-w-sm w-full text-sm text-accentDark mt-3 leading-7">
        You are using an invalid character for the account creation, Please make
        sure special characters like "-, &, %" are not present.
      </p>
    </trac-modal>

    <trac-confirmation :type="type" :active="active">
      {{ message }}
    </trac-confirmation>

    <trac-modal v-if="accountNotVerified" :cancel="false" size=" max-w-xl">
      <div class="flex justify-center items-center gap-6 flex-col">
        <img src="./../../assets/svg/warning_amber.svg" alt="" class="">
        <p class="text-xs text-center">Kindly note that you will need to complete your KYC verification before creating
          your fixed account.</p>

        <trac-button @button-clicked="routeToKYC" class="block mt-8">COMPLETE KYC</trac-button>
      </div>
    </trac-modal>
  </main>
</template>

<script>
import moment from 'moment';
import {
  GET_USER_DATA,
  GET_USER_BUSINESS_DATA,
  SAVE_LOCAL_DB_DATA,
  GET_USER_TOKEN
} from '../../browser-db-config/localStorage';
import { eventBus } from '../../eventBus';

export default {
  data() {
    return {
      accountNotVerified: false,
      phoneData: null,
      emailData: null,
      addressData: null,
      documentData: null,
      bvnData: null,
      fixedAccount: [],
      toggleModal: false,
      stores: [],
      accountName: '',
      store: '',
      active: false,
      message: '',
      type: false,
      newAccount: false,
      moment,
      search: '',
      loading: false,
      bvnCheck: false,
      bvnCheckTwo: false,
      dashError: false,
      newUserModal: false,
      pendingUserModal: false,
      name: '',
      banks: [],
      selectedBank: null,
      selectedFilter: null,
    };
  },

  computed: {
    fixedAccountVerified() {
      return ((this.phoneData.status === 'OVERRIDE' || this.phoneData.status === 'VERIFIED') &&
        (this.emailData.status === 'OVERRIDE' || this.emailData.status === 'VERIFIED') &&
        (this.bvnData.status === 'OVERRIDE' || this.bvnData.status === 'VERIFIED') &&
        (this.documentData.status === 'OVERRIDE' || this.documentData.status === 'VERIFIED') &&
        (this.addressData.status === 'OVERRIDE' || this.addressData.status === 'VERIFIED'));
    },
    filterOptions() {
      const bankList = this.banks.map(bank => Object.assign(bank, { type: 'bank' }))
      const storesList = this.stores.map(store => Object.assign(store, { type: 'store' }))
      bankList.push({ name: 'all' })
      return [].concat(bankList, storesList)
    },
    filteredAccounts() {
      const searchByText = (value) => {
        return value.toLowerCase().includes(this.search.toLowerCase());
      };
      const searchByBank = (value, code) => {
        const matches = value.bank_code === code;
        return matches;
      };
      const searchByStore = (value, storeId) => {
        const matches = value.store_details.store_id === storeId;
        return matches;
      };
      let process = [];
      if (this.selectedFilter) {
        if (this.selectedFilter.name === 'all') {
          process = this.fixedAccount.filter((account) =>
            searchByText(account.custom_account_name)
          );
        } else if (this.selectedFilter.type === 'bank') {
          process = this.fixedAccount.filter(
            (account) =>
              searchByText(account.custom_account_name) &&
              searchByBank(account, this.selectedFilter.bank_code_old)
          );
        } else if (this.selectedFilter.type === 'store') {
          process = this.fixedAccount.filter(
            (account) =>
              searchByText(account.custom_account_name) &&
              searchByStore(account, this.selectedFilter.id)
          );
          // console.log('store filter method selected', process.map(item => item.store_details.store_id), this.selectedFilter.id)
        }
      } else {
        process = this.fixedAccount.filter((account) =>
          searchByText(account.custom_account_name)
        );
      }
      return process;
    },
  },

  methods: {
    closeModal() {
      this.$store.commit('CHANGE_FIXED_ACCOUNT_MODAL');
      this.toggleModal = false;
    },
    updateLocalStores() {
      this.$store.dispatch('FETCH_ALL_STORES_ASSIGNED_TO_USER').then((data) => {
        const user = GET_USER_DATA();
        const token = GET_USER_TOKEN();
        user.stores = data.data || [] // get full list of store and make sure the 

        SAVE_LOCAL_DB_DATA("traction-app-user-data", { token, user });
      })
    },
    routeToKYC() {
      this.$router.push({
        name: 'KycProfile',
        params: {
          custom: true,
        }
      })
    },
    async checkVerification() {
      let result = await this.$store.dispatch("VERIFY_KYC");
      const status = result.data;
      const account = status.find(({ name }) => name === "account");
      const email = status.find(({ name }) => name === "email");
      const phone = status.find(({ name }) => name === "phone");
      const bvn = status.find(({ name }) => name === "bvn");
      const document = status.find(({ name }) => name === "document");
      const address = status.find(({ name }) => name === "address");

      this.phoneData = phone;
      this.emailData = email;
      this.bvnData = bvn;
      this.documentData = document;
      this.addressData = address;
    },
    async getAccount() {
      this.loading = true;
      let res = await this.$store.dispatch('GET_FIXED_ACCOUNT');
      if (res.status) {
        this.loading = false;
        res.data
          ? (this.fixedAccount = res.data.items || [])
          : (this.fixedAccount = []);
      } else {
        this.loading = false;
        // this.bvnCheck = true;
        eventBus.$emit('trac-alert', { message: res.message });
      }
    },
    async createFixedAccount() {
      if (this.accountName == '') {
        this.active = true;
        this.type = false;
        this.message = 'Please enter an account name';
        return;
      }
      if (this.store === '') {
        this.active = true;
        this.type = false;
        this.message = 'Please select a store';
        return;
      }
      if (!this.selectedBank) {
        this.active = true;
        this.type = false;
        this.message = 'Please select a Preferred Bank';
        return;
      }
      this.loading = true;
      let payload = {
        account_name: this.accountName,
        store_id: this.store.id,
        providerCode: this.selectedBank.bank_code_new,
        isFixed: true
      };

      let res = await this.$store.dispatch('CREATE_FIXED_ACCOUNT', payload);
      if (res.status) {
        this.loading = false;
        this.active = true;
        this.type = true;
        this.message = res.message;
        this.newAccount = false;
        this.getAccount();
        this.updateLocalStores()
        setTimeout(() => {
          this.active = false;
        }, 3000);
      } else {
        if (res.error == 'invalid character: -') {
          this.loading = false;
          this.dashError = true;
        } else {
          this.loading = false;
          // this.bvnCheckTwo = true;
          eventBus.$emit('trac-alert', { message: res.message });
        }
      }
    },
  },
  async created() {
    this.loading = true;
    await this.checkVerification();

    if (!this.fixedAccountVerified) {
      this.loading = false;
      this.accountNotVerified = true;
      return;
    }

    this.name = GET_USER_DATA().name;
    let result = await this.$store.dispatch('VERIFY_KYC');
    const bankList = (await this.$store.dispatch('GET_BANKS')) || [];
    this.banks = Array.isArray(bankList.data) ? bankList.data : [];
    this.loading = false;

    if (result && result.data) {
      const document = result.data.find(({ name }) => name === 'document');
      this.$store.commit('UPDATE_KYC_DOC_STATUS', document.status);
    }
    const docStatus = this.$store.state.miscellaneous.kycDocumentState;

    if (docStatus === 'VERIFIED' || docStatus === 'OVERRIDE') {
      if (this.$route.params.openModal) {
        this.newAccount = true;
      }
      await this.getAccount();
      // await this.$store.dispatch("FETCH_ALL_STORES");
      // console.log('Hard Fetch: ', onlineFetchStores)
      // const res = this.$store.getters["GET_ALL_STORES"];
      const res = (await this.$store.dispatch('HARD_FETCH_ALL_STORES')) || [];

      if (res.status) {
        this.stores = (res.data || []).map((store) => {
          return { ...store, id: store._id };
        });
        // debugger
      } else {
        eventBus.$emit('trac-alert', { message: res.message });
      }
    } else if (docStatus === 'PENDING') {
      this.pendingUserModal = true;
    } else {
      this.newUserModal = true;
    }
  },
};
</script>

<style>

</style>
